import React from "react";
import SocialMediaLinks from "../socila-media-links/SocialMediaLinks";

const Footer = () => {
  return (
    <div className="row justify-between">
      <div
        className="col-lg-3 col-md-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index.html" className="logo footer-logo">
          <img src="images/logo/deski_01.png" alt="" />
        </a>
      </div>
      <SocialMediaLinks />
    </div>
    //.row
  );
};

export default Footer;
