import React from "react";

const SocialMediaLinks = () => {
  const socialContent = [
    {
      icon: "fa-facebook",
      link: "https://www.facebook.com/profile.php?id=61560547466022",
    },
    {
      link: "https://www.tiktok.com/@rotaio.io",
      svg: (
        <svg
          width={20}
          height={20}
          xmlns="http://www.w3.org/2000/svg"
          fill="#73737a"
          viewBox="0 0 448 512"
        >
          <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
        </svg>
      ),
    },
    {
      icon: "fa-linkedin",
      link: "https://www.linkedin.com/company/rotaio/",
    },
    {
      icon: "fa-youtube",
      link: "https://www.youtube.com/channel/UCn1WNlnMfMsZWVdoCB4B_4Q",
    },

    {
      icon: "fa-instagram",
      link: "https://www.instagram.com/rotaio.io/",
    },
    {
      link: "https://x.com/rotaio_io",
      svg: (
        <svg
          width={20}
          height={20}
          fill="#73737a"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
        </svg>
      ),
    },
  ];
  return (
    <div className="col-lg-7 col-md-12">
      <ul className="d-flex gap-3 justify-content-center">
        {socialContent.map((val, i) => (
          <li key={i}>
            <a href={val.link} target="_blank" rel="noreferrer">
              {val.icon ? (
                <i className={`fa ${val.icon} fa-lg`}></i>
              ) : (
                <>{val.svg}</>
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SocialMediaLinks;
