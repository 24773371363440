import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import FaqClassic from "../../components/faq/FaqClassicTwo";
import CounterOne from "../../components/counter/CounterOne";
import CallToActionFour from "../../components/call-to-action/CallToActionFour";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import BlockMetaData from "../../components/fancy-text-block/BlockMetaData";
import CallToActionEight from "../../components/call-to-action/CallToActionEight";

const RotaioLandingPage = () => {
  // const handleSubmit = (event) => {
  //   event.preventDefault();
  // };

  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Rotaio || Peer 2 Peer Saving and Lending Club</title>
      </Helmet>
      {/* End Page SEO Content */}
      <Header />
      <div id="home"></div>
      {/* End Header */}
      {/* 
     =============================================
			Hero Banner One
		============================================== */}
      <div className="hero-banner-one">
        <div className="hero-upper-container">
          <div className="icon-box-one">
            <img src="images/logo/01.svg" alt="logo" />
          </div>
          <div className="icon-box-two">
            <img src="images/logo/02.svg" alt="logo" />
          </div>
          <div className="icon-box-three">
            <img src="images/logo/03.svg" alt="logo" />
          </div>
          <div className="icon-box-four">
            <img src="images/logo/04.svg" alt="logo" />
          </div>
          <div className="icon-box-five">
            <img src="images/logo/05.svg" alt="logo" />
          </div>
          <div className="icon-box-six">
            <img src="images/logo/06.svg" alt="logo" />
          </div>
          <div className="icon-box-seven">
            <img src="images/logo/07.svg" alt="logo" />
          </div>
          <div className="icon-box-eight">
            <img src="images/logo/08.svg" alt="logo" />
          </div>

          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
          {/* End all bubble images */}

          <div className="container ">
            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <h1 className="font-rubik hero-heading fs-1">
                  Saving and lending club with your <span>peers</span>
                </h1>
                <p className="hero-sub-heading">
                  Say goodbye to interest rate, bad credit and join the power of
                  community financing
                </p>
              </div>
              {/* End .col */}
            </div>
            {/* End row */}

            <Link
              target="_blank"
              to="https://share.hsforms.com/1tSFVHOv4Q9axRk4H-RhlhQqtidk"
              className="w-auto p-3 lg:w-25 brand-button m-auto text-white center-vertically"
            >
              Join the waitlist
            </Link>
          </div>
          {/* End .container */}
        </div>
        {/* /.hero-upper-container */}
      </div>
      {/* /.hero-banner-one */}
      {/* 
     =============================================
				Fancy Feature One
		============================================== */}
      <div className="fancy-feature-one pb-150 md-pb-80">
        <div className=" fancy-main-image-continer text-center">
          <div className="feature-img-area">
            <img
              src="images/assets/feature-img-01.svg"
              className="main-image"
              alt="feature"
            />
            <img
              src="images/assets/feature-img-01-01.svg"
              alt="feature"
              className="cs-screen animation-image animation"
            />
          </div>
          {/* /.feature-img-area */}
        </div>
        {/* /.text-center */}

        {/* 
        =============================================
				Fancy Block Style One
		    ============================================== */}

        {/* /.block-style-one */}
      </div>

      {/* /.fancy-feature-one */}
      <div id="how" className="mt-20">
        <div className="block-style-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-9 m-auto">
                <div className="title-style-one text-center mb-190 md-mb-100">
                  <h2>How Rotaio works?</h2>
                </div>
                {/* /.title-style-one */}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="d-lg-flex align-items-center justify-content-between inner-container">
              <BlockMetaData />
            </div>
          </div>
          {/* /.container */}
        </div>
      </div>
      {/* ============================================= Fancy Feature Two
      ============================================== */}
      <div className="fancy-feature-two mt-45">
        <div className="container">
          <div className="row align-items-center block-style-two pb-250 md-pb-90">
            <div className="col-lg-7 col-md-8 m-auto">
              <div data-aos="fade-right" data-aos-duration="1200">
                <div className="img-holder img-holder-one">
                  <div
                    className="cs-screen progress-line-one"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  ></div>
                  <div
                    className="cs-screen progress-line-two"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  ></div>
                  <img src="images/assets/feature-img-05.png" alt="feature" />
                  <img
                    src="images/shape/1.svg"
                    alt="feature"
                    className="cs-screen screen-one"
                  />
                  <img
                    src="images/shape/2.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>
            {/* End .col */}
            <div className="col-lg-5 ">
              <div
                className="text-wrapper"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#FF5291" }}>
                  Community
                </h6>
                <h3 className="font-gilroy-bold fs-3">
                  Where peers connect and interest disconnect.
                </h3>
                <p>
                  Experience the power of community with Rotaio, where your only
                  interest is each other's success. Dive into a world of mutual
                  support and financial access with zero interest peer-to-peer
                  saving and lending club.
                </p>
                {/* <a href="#">Learn about flexibility</a> */}
              </div>
              {/*  /.text-wrapper */}
            </div>{" "}
            {/* End .col */}
          </div>
          {/* /.block-style-two */}

          <div className="row align-items-center block-style-two pb-250 md-pb-90">
            <div className="col-lg-6 col-md-8 m-auto order-lg-last">
              <div data-aos="fade-left" data-aos-duration="1200">
                <div className="img-holder img-holder-two">
                  <img src="images/assets/feature-img-06.png" alt="feature" />
                  <img
                    src="images/shape/4.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>{" "}
            {/* End .col */}
            <div className="col-lg-6 order-lg-first">
              <div
                className="text-wrapper ps-5 pe-5"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#AA4CFF" }}>
                  Quick & Savvy
                </h6>
                <h3 className="font-gilroy-bold fs-3">
                  {" "}
                  Your fast steps to financial empowerment
                </h3>
                <p>
                  Skip the line and don't wait for banks lengthy, complicated
                  application and approval process. Here saving and lending is
                  not a transaction it's an experience.
                </p>
                {/* <div className="quote">
                  <blockquote>
                    “Our team really feel great to use the deski app specially
                    their faster load & server speed”
                  </blockquote>
                  <div className="d-flex align-items-center">
                    <img src="images/media/img_01.png" alt="media" />
                    <div className="info-meta">
                      <h5>Micle Duke, Product Manager</h5>
                      <span className="font-rubik">Uber Inc.</span>
                    </div>
                  </div>
                </div> */}
                {/*  /.quote */}
              </div>
              {/*  /.text-wrapper */}
            </div>{" "}
            {/* End .col */}
          </div>
          {/* /.block-style-two */}

          <div className="row align-items-center block-style-two pb-170 md-pb-50">
            <div className="col-lg-7 col-md-7 m-auto">
              <div data-aos="fade-right" data-aos-duration="1200">
                <div className="img-holder img-holder-three">
                  <img src="images/assets/feature-img-07.png" alt="feature" />
                  <img
                    src="images/shape/5.svg"
                    alt="feature"
                    className="cs-screen custom-bubble"
                  />
                  <img
                    src="images/shape/6.svg"
                    alt="feature"
                    className="cs-screen dot-shape"
                  />
                  <div className="bubble-one"></div>
                  <div className="bubble-two"></div>
                  <div className="bubble-three"></div>
                  <div className="bubble-four"></div>
                </div>
                {/* /.img-holder */}
              </div>
            </div>{" "}
            {/* End .col */}
            <div className="col-lg-5">
              <div
                className="text-wrapper"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <h6 className="font-rubik" style={{ color: "#03DDD5" }}>
                  Financial Intelligence
                </h6>
                <h3 className="font-gilroy-bold fs-3">
                  Kick off your smart money journey and improve your credit
                  score
                </h3>
                <p>
                  Rotaio helps you with financial planning, building your credit
                  and saving habits, empowering you and your peers to buy what
                  you need without interest and debt free.
                </p>
              </div>
              {/* /.text-wrapper */}
            </div>{" "}
            {/* End .col */}
          </div>
          {/* /.block-style-two */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-feature-two */}
      {/* 
     =============================================
			Usable Tools Section
		============================================== */}
      <div className="useable-tools-section bg-color mt-140 mb-50 md-mt-80">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="container">
          <h6 className="font-rubik">Smart money for your employees</h6>
          <h2 className="fs-2">
            Empower your team with the tool that will enable them to thrive
            financially, drive the community culture within and help them to
            have stronger relationships. Rotaio's user journey is aimed to allow
            your team members remagine what's possible when it comes to
            financial habits.
          </h2>
          <img alt="feature" src="images/assets/feature-img-new.png" />

          {/* <SocialTwo /> */}
        </div>
        {/* /.container */}
      </div>
      {/* /.useable-tools-section */}
      {/* 
     =============================================
				Fancy Feature Three
		============================================== */}
      <div className="fancy-feature-three pt-200 md-pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-one">
                <h6 className="font-rubik">
                  Join us to disrupt the financial industry
                </h6>
                <h2 className="fs-3">
                  Access smart funds faster, grow your business and build your
                  community
                </h2>
              </div>
              {/* /.title-style-one */}
              <div className="custom-container">
                <CounterOne />
              </div>
              {/* /.counter-info-classic */}
            </div>

            <div className="col-lg-6">
              <div className="clients-feedback-classic md-mt-80">
                <div
                  className="feedback-wrapper"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img
                    src="images/media/img_01.png"
                    alt=""
                    className="media-meta"
                  />
                  <p>
                    I joined Rotaio's waitlist! As a small music school owner,
                    collaborating with other business owners through Rotaio will
                    allow us to pool our resources and buy instruments more
                    affordably. I believe this platform will revolutionize how
                    small businesses like mine manage funds and accelerate
                    growth.
                  </p>
                  <h6 className="name">Azra - Small business owner.</h6>
                  {/* <span className="font-rubik disg-info">
                    Former Assistant Jamuna Group.
                  </span> */}
                </div>{" "}
                {/* /.feedback-wrapper */}
                <div
                  className="feedback-wrapper"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img
                    src="images/media/img_02.png"
                    alt=""
                    className="media-meta"
                  />
                  <p>
                    My wife and I are committed to staying out of debt. With
                    Rotaio, we see a future where we can manage our finances
                    through community support rather than relying on credit.
                    It's more than just savings; it's about making financially
                    healthy choices that align with our lifestyle and values.
                    We're ready to start our journey to financial freedom with
                    Rotaio.
                  </p>
                  <h6 className="name">
                    Ash - Family man and supportive husband.
                  </h6>
                  {/* <span className="font-rubik disg-info">
                    UI designer Baper Inc.
                  </span> */}
                </div>{" "}
                {/* /.feedback-wrapper */}
                <div
                  className="feedback-wrapper"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img
                    src="images/media/img_03.png"
                    alt=""
                    className="media-meta"
                  />
                  <p>
                    I see Rotaio as a game-changer in how we can support each
                    other at work. Joining the waitlist was an easy decision for
                    me. Rotaio's platform fosters a unique opportunity for
                    employees to connect and support one another beyond the
                    workspace. It’s a tool that enhances our ability to
                    collaborate on a personal financial level, which in turn
                    builds stronger, more empathetic teams.
                  </p>
                  <h6 className="name">Nihad - Software engineer</h6>
                  {/* <span className="font-rubik disg-info">
                    Front-end coder HeloShape.
                  </span> */}
                </div>
                {/* /.feedback-wrapper */}
              </div>
              {/* /.clients-feedback-classic */}
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-feature-three */}
      {/* 
     =============================================
			Call To Action
		============================================== */}
      <div className="fancy-short-banner-one">
        <div className="container">
          <CallToActionFour />
        </div>
        {/* /.container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-short-banner-one */}

      {/* 
      =============================================
			  Tech  partners
	    ============================================== 
    */}
      <div className="fancy-short-banner-one partners-section mt-5">
        <div className="container">
          <CallToActionEight />
        </div>
        {/* /.container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* 
     =============================================
				Faq Classic
		============================================== */}
      <div id="Faq" className="faq-classic pt-225 md-pt-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-one">
                <h6 className="font-rubik">Find your answers</h6>
                <h2>Have any thought? Look here.</h2>
              </div>
            </div>
            {/* End .col */}
            <div className="col-lg-6">
              <div className="md-mt-60">
                <FaqClassic />
              </div>
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.faq-classic */}
      {/* 
      =============================================
				Footer
		  ============================================== */}
      <div className="footer-bg-wrapper">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="fancy-short-banner-two">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
              >
                <h2 className="font-gilroy-bold fs-3">
                  Have a question, feedback or request?
                </h2>
                <Link
                  target="_blank"
                  to="https://share.hsforms.com/1mSFuFsqxS9OISR7YUATevAqtidk"
                >
                  Contact us
                </Link>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-short-banner-two */}

        <footer className="theme-footer-one pt-130 md-pt-70">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default RotaioLandingPage;
