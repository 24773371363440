import React from "react";
// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";

// const counterUpContent = [
//   {
//     startCount: "0",
//     endCount: "13",
//     meta: "Projects",
//     colorClass: "color-one",
//   },
//   {
//     startCount: "0",
//     endCount: "3",
//     meta: "Workers",
//     colorClass: "color-two",
//   },
//   {
//     startCount: "0",
//     endCount: "123",
//     meta: "Offices",
//     colorClass: "color-three",
//   },
// ];

const CounterOne = () => {
  // const [focus, setFocus] = React.useState(false);
  return (
    <div className="counter-info-classic">
      <img alt="wait list" src="/images/assets/feature-wait-list.png" />
      <div className="bubble-one"></div>
      <div className="bubble-two"></div>
      <div className="bubble-three"></div>
      <div className="bubble-four"></div>
      <div className="bubble-five"></div>
      <div className="bubble-six"></div>
      <div className="bubble-seven"></div>
      <img src="images/shape/8.svg" alt="" className="cs-screen dot-shape" />
    </div>
  );
};

export default CounterOne;
