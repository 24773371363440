import React from "react";
import { Link } from "react-router-dom";

const CallToActionFour = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#787CFF" }}>
            Ready for financial freedom?
          </h6>
          <h2 className="fs-3">
            {" "}
            By joining our waitlist, you will get exclusive access to all
            features for free.
          </h2>
        </div>
        {/* /.title-style-one */}
      </div>
      <div className="col-lg-6">
        <div className="form-wrapper">
          <Link
            target="_blank"
            to="https://share.hsforms.com/1tSFVHOv4Q9axRk4H-RhlhQqtidk"
            id="waitlist-button"
            className=" brand-button m-auto text-white"
          >
            Join the waitlist
          </Link>
        </div>
        {/* /.form-wrapper */}
      </div>
    </div>
  );
};

export default CallToActionFour;
