import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "How does Rotaio work?",
    desc: `Rotaio operates on a community-based financial model where members form a circle to collectively save and borrow money. Each member contributes a fixed amount monthly, and every month, one member receives the total pooled amount. This cycle continues until every member has received their turn for the funds.`,
    expand: "a",
  },
  {
    title: "Who can get access to Rotaio?",
    desc: `Rotaio is a closed club accessible through invitation only. You can join a circle either by receiving an invite from your peers or through your employer. This exclusive setup ensures trust and reliability among all members of the community.`,
    expand: "b",
  },
  {
    title: "Is the app available for iOS and Android?",
    desc: `Yes, Rotaio will be available soon for both iOS and Android devices. We aim to make our platform accessible to everyone, so alongside our mobile applications, we will launch a web version for enhanced accessibility.`,
    expand: "c",
  },
  {
    title: "Is the model really interest-free?",
    desc: `Absolutely! Rotaio is committed to fostering community support without the burden of interest, we charge facilitation fees like opening a bank account. Members receive exactly what they contribute, without any interest fees. This model promotes fairness and mutual benefit, distinguishing Rotaio from traditional financial services.`,
    expand: "d",
  },
  {
    title: "How do I send and receive money on Rotaio?",
    desc: `Rotaio uses Plaid, an industry leader in financial technology, to securely access bank accounts for transactions. Your monthly contributions are automatically deducted from your linked bank account, and when it's your turn, the total pooled amount is deposited into your account. This process is seamless, secure, and ensures that all transactions are handled with utmost integrity.`,
    expand: "e",
  },
];

const FaqClassic = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassic;
