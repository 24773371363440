import React from "react";
import { Link } from "react-router-dom";

const CallToActionEight = () => {
  return (
    <div className="row align-items-center mt-1">
      <div className="col-lg-6">
        <div className="row justify-content-center align-items-center">
          <Link target="_blank" to="https://plaid.com/">
            <img
              alt=""
              width={150}
              height={150}
              src="images/assets/plaid.png"
            />
          </Link>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#787CFF" }}>
            Tech partner
          </h6>
          <h2 className="fs-5">
            We use Plaid, a leader in financial technology, to securely connect
            your bank accounts with Rotaio. Plaid specializes in creating secure
            connections for financial transactions and verifying identities,
            ensuring that your money transfers are safe and your financial data
            is protected
          </h2>
        </div>
        {/* /.title-style-one */}
      </div>
    </div>
  );
};

export default CallToActionEight;
