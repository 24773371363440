import React from "react";

const CopyRight = () => {
  return (
    <div className="d-md-flex align-items-center justify-content-between">
      {/* <ul className="order-md-last">
        <li>
          <Link to="/terms-conditions"></Link>
        </li>
        <li>
          <Link to="/terms-conditions">Security</Link>
        </li>
        <li>
          <Link to="/terms-conditions">Terms</Link>
        </li>
      </ul> */}

      <p>Copyright @{new Date().getFullYear()} Rotaio.</p>
    </div>
  );
};

export default CopyRight;
