import React from "react";

const BlockMetaData = () => {
  const blockMetaCotent = [
    {
      _id: 1,
      icon: "01",
      title: "Create fund",
      metaHoverContent: `Let's say you need $1000 for a summer trip and you can pay $100 per month, that means your fund cycle is 10 month.`,
    },
    {
      _id: 2,
      icon: "02",
      title: "Invite peers",
      metaHoverContent: ` Now bring your trusted friends or family who also want $1000, into your fund circle, everyone will chip in the same monthly amount into the pool.`,
    },
    {
      _id: 3,
      icon: "03",
      title: "Payout order",
      metaHoverContent: ` Here is where you set the payout order so everyone has a turn to get paid.`,
    },
    {
      _id: 4,
      icon: "04",
      title: "Receive and rotate",
      metaHoverContent: ` Watch the magic happen as each member takes their turn to use the collective pot for their need and then the cycle repeats`,
    },
  ];
  return (
    <>
      {blockMetaCotent.map((blockMeta) => (
        <div className="block-meta-data text-center" key={blockMeta._id}>
          <div className="line-dot-container">
            <div className="icon-box">
              <img src={`images/icon/${blockMeta.icon}.svg`} alt="icon" />
            </div>
            {/* /.icon-box */}
            <p>{blockMeta.title}</p>
          </div>
          <div className="hover-content">
            <span>{blockMeta.metaHoverContent}</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default BlockMetaData;
